import { useAlert } from "context/alert/AlertContext";
import { useState } from "react";
import { AlertType } from "types/enum";

export const useDelete = <T>({
  getMessage,
  handleDelete,
  refetch
}: {
  getMessage: (data: T) => string;
  handleDelete: (data: T) => Promise<boolean>;
  refetch?: () => Promise<void> | void;
}) => {
  const { showAlert } = useAlert();
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] = useState("");
  const [itemToBeDeleted, setItemToBeDeleted] = useState<T>();

  const handleConfirmDelete = (data: T): void => {
    setDeleteConfirmationMessage(getMessage(data));
    setItemToBeDeleted(data);
  };

  const handleDeleteConfirmation = async (): Promise<void> => {
    if (!itemToBeDeleted) {
      return;
    }
    if (await handleDelete(itemToBeDeleted)) {
      showAlert(AlertType.SUCCESS);
    } else {
      showAlert(AlertType.DANGER);
    }
    handleDeleteCancellation();
    await refetch?.();
  };

  const handleDeleteCancellation = (): void => {
    setDeleteConfirmationMessage("");
    setItemToBeDeleted(undefined);
  };

  return {
    deleteConfirmationMessage,
    handleConfirmDelete,
    handleDeleteConfirmation,
    handleDeleteCancellation
  };
};

export type UseDeleteType<T> = ReturnType<typeof useDelete<T>>;
