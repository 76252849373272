import React from "react";
import { Link, useParams } from "react-router-dom";
import Title from "components/Shared/Title";
import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";

export const PostCreateTemplatePage = (): React.ReactElement => {
  const { productId } = useParams();

  return (
    <div
      className="w-full h-full flex flex-col items-center justify-center bg-white rounded-lg p-4 lg:p-10 mobile-full-screen"
      data-testid="noItems">
      <img src="/images/svg/deliver.svg" />
      <Title className="pt-10 pb-3 text-center">Product template is successfully published</Title>
      <p className="text-grey-50 px-4 text-center">
        The template’s configuration is copied from the base product. However, you can modify the
        configuration independent of the base product.
      </p>
      <Link to={`/templates/${productId}`} data-testid="" className="mt-8">
        <IconButton icon={<Plus />}>Review template configuration</IconButton>
      </Link>
      <Link to="/templates/published" data-testid="" className="mt-4 link">
        View all your templates
      </Link>
    </div>
  );
};
