import { CreateEditProductVariant } from "components/CreateEditProductVariant/CreateEditProductVariant";
import { useProductVariant } from "hooks/useProductVariant/useProductVariant";
import React from "react";
import { useParams } from "react-router-dom";
import { SegmentEvent } from "types/enum";

export const CreateTemplateVariantPage = (): React.ReactElement => {
  const { templateId } = useParams();
  const variantProps = useProductVariant({
    type: "ProductTemplate",
    baseUri: `product-template/${templateId}`,
    backURL: `/templates/published/${templateId}/variants`,
    addEvent: SegmentEvent.TEMPLATE_VARIANT_ADDED,
    updateEvent: SegmentEvent.TEMPLATE_VARIANT_MODIFIED
  });

  return <CreateEditProductVariant {...variantProps} />;
};

export default CreateTemplateVariantPage;
