import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ErrorProps } from "types";

const Error = (props: ErrorProps) => {
  const { error, close, onClose } = props;
  return error ? (
    <div
      className="relative shadow flex items-center p-4 mb-5 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 pr-8"
      role="alert">
      <XCircleIcon className="w-10 mr-2" />
      <span className="sr-only">Error</span>
      <div data-testid="error">{error}</div>
      {close && (
        <span className="absolute right-2 top-2 p-2 cursor-pointer" onClick={onClose}>
          <span className="sr-only">Close Alert</span>
          <XMarkIcon className="w-5 h-5" />
        </span>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Error;
