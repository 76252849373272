import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import api from "config/api";
import { storage } from "config/firebase";
import { appState } from "config/store";
import { FETCH_FAILED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { extractErrorMessage } from "helpers/api";
import { isNotEmpty, isNumber, isObjNotEmpty } from "helpers/validate";
import { useDelete } from "hooks/shared/useDelete/useDelete";
import useProduct from "hooks/useProduct";
import useSort from "hooks/useSort";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Category,
  FormChangeEvent,
  Response,
  Template,
  TemplateForm,
  TemplateLoaders,
  TemplateValidity,
  Variant
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

export const useTemplate = () => {
  const { templateId } = useParams();
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const defaultTemplateForm: TemplateForm = {
    name: "",
    productId: "",
    categories: [],
    currency: "",
    price: "0",
    description: "",
    images: []
  };

  // UseStates
  const [template, setTemplate] = useState<Template>();
  const [templateForm, setTemplateForm] = useState<TemplateForm>(defaultTemplateForm);
  const [formIsValid, setFormIsValid] = useState<TemplateValidity>({
    description: false,
    details: false,
    images: false
  });
  const [loaders, setLoaders] = useState<TemplateLoaders>({
    savingTemplate: false,
    fetchingTemplate: true,
    savingImage: false,
    savingDesc: false,
    uploadingImage: false
  });
  const [isOpen, setIsOpen] = useState(false);
  const [variants, setVariants] = useState<Variant[]>([]);
  const sort = useSort(setVariants);

  const [error, setError] = useState("");
  const {
    products,
    handleGetProducts,
    loaders: { fetchingProduct }
  } = useProduct();
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeStep, setActiveStep] = useState("Template details");

  const handleFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    const data: Partial<TemplateForm> = {};
    if (name === "productId") {
      data.name = products?.products.find((product) => product.id === value)?.name || "";
    }
    if (name === "categoryId") {
      data.categories = [value].concat(templateForm.categories);
    }
    setTemplateForm((prev) => ({
      ...prev,
      [name]: value,
      ...data
    }));
    setError("");
  };

  const handleEditTemplate = async (): Promise<boolean> => {
    const { name, categories, price } = templateForm;
    try {
      const json: Response<string> = await api
        .put(`product-template/${templateId}`, {
          json: { name, categories, price: +price }
        })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_MODIFIED, {
          templateId,
          name: templateForm.name,
          categories: templateForm.categories,
          productId: templateForm.productId
        });
        navigate("?step=Images");
      }
      return isSuccessfull;
    } catch (error) {
      setError(await extractErrorMessage(error));
      return false;
    }
  };

  const handleCreateTemplate = async (): Promise<boolean> => {
    const { name, productId, categories, price, currency } = templateForm;

    try {
      const json: Response<string> = await api
        .post("product-template", {
          json: { name, productId, categories, price: +price, currency }
        })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_ADDED, {
          name: templateForm.name,
          categories: templateForm.categories,
          productId: templateForm.productId
        });
        navigate(`${json.data}?step=Images`);
      }
      return isSuccessfull;
    } catch (error) {
      setError(await extractErrorMessage(error));
      return false;
    }
  };

  const handleDeleteTemplate = async (template: Template): Promise<boolean> => {
    try {
      const json: Response<void> = await api.delete(`product-template/${template.id}`).json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setError("");

    if (+templateForm.price > 0) {
      setIsOpen(true);
      return;
    }

    setLoaders((prev) => ({ ...prev, savingTemplate: true }));
    let result;
    if (templateId) {
      result = await handleEditTemplate();
    } else {
      result = await handleCreateTemplate();
    }
    setLoaders((prev) => ({ ...prev, savingTemplate: false }));
    if (result) {
      setActiveStep("Images");
      showAlert(AlertType.SUCCESS);
    }
  };

  const handleGetTemplate = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingTemplate: true }));

    try {
      const json: Response<Template> = await api.get(`product-template/${templateId}`).json();
      if (json.code === 200) {
        setTemplate(json.data);
        setTemplateForm({
          name: json.data.name,
          productId: "",
          categories: json.data.categories.map((category) => category.id),
          currency: json.data.currency,
          price: json.data.price,
          description: json.data.description || "",
          images: json.data.images || []
        });
        setVariants(json.data.variants || []);
      }
    } catch (err) {
      showAlert(AlertType.DANGER, FETCH_FAILED);
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingTemplate: false }));
  };

  const handleGetBusinessCategories = async (): Promise<void> => {
    try {
      const json: Response<Category[]> = await api
        .get(`product-template/business-types/${appState.business.get()?.businessType}`)
        .json();
      if (json.code === 200) {
        setCategories(json.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveCategory = (categoryId: string): void => {
    setTemplateForm((prev) => ({
      ...prev,
      categories: prev.categories.filter((category) => category !== categoryId)
    }));
  };

  const handleImageUpload = (images: File[]): void => {
    setLoaders((prev) => ({ ...prev, uploadingImage: true }));

    images.forEach((image, index) => {
      const storageRef = ref(storage, `images/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // You can add progress tracking here if needed
        },
        (error) => {
          console.error("Image upload failed:", error);
          showAlert(AlertType.DANGER, "Image upload failed");
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            setTemplateForm((prev) => ({
              ...prev,
              images: [
                ...prev.images,
                { url: downloadURL, isDefault: prev.images.length == 0, thumbnailUrl: downloadURL }
              ]
            }));
            showAlert(AlertType.SUCCESS, "Image uploaded successfully");
          } catch (error) {
            console.error("Failed to get download URL:", error);
            showAlert(AlertType.DANGER, "Failed to get download URL");
          }
        }
      );
    });

    setLoaders((prev) => ({ ...prev, uploadingImage: false }));
  };

  const handleSaveImages = async (): Promise<boolean> => {
    const { images } = templateForm;

    if (images.length === 0) {
      navigate("?step=Description");
      return true;
    }
    try {
      const json: Response<string> = await api
        .put(`product-template/${templateId}/images`, { json: { images } })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_IMAGE_SAVED, {
          name: templateForm.name
        });
      }
      navigate("?step=Description");
      return isSuccessfull;
    } catch (error) {
      setError(await extractErrorMessage(error));
      return false;
    }
  };

  const handleSaveDescriptionAndPublish = async (): Promise<boolean> => {
    const { description, images } = templateForm;

    try {
      const json: Response<string> = await api
        .put(`product-template/${templateId}/description`, { json: { description } })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(SegmentEvent.TEMPLATE_DESC_SAVED, {
          name: templateForm.name
        });
      }
      if (isSuccessfull && images.length > 0 && template?.status == "unpublish") {
        await handleTogglePublish();
        navigate(`/templates/published/${templateId}/post-create`);
      } else {
        showAlert(AlertType.SUCCESS, "Template saved successfully");
        navigate(`/templates/published/${templateId}`);
      }
      return isSuccessfull;
    } catch (error) {
      setError(await extractErrorMessage(error));
      return false;
    }
  };

  const handleRemoveImage = (i: number): void => {
    setTemplateForm((prev) => {
      const removedImage = prev.images[i];
      return {
        ...prev,
        images: prev.images
          .filter((image, index) => index !== i)
          .map((image, index) => ({
            ...image,
            isDefault: image.isDefault || (index === 0 && removedImage.isDefault)
          }))
      };
    });
  };

  const handleToggleDefault = (i: number): void => {
    setTemplateForm((prev) => ({
      ...prev,
      images: prev.images.map((image, index) => ({
        ...image,
        isDefault: i === index
      }))
    }));
  };

  const handleTogglePublish = async (): Promise<void> => {
    try {
      const json: Response<string> = await api
        .post(
          `product-template/${templateId}/status/${
            template?.status == "DRAFT" ? "publish" : "unpublish"
          }`,
          { json: {} }
        )
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        const templateAction =
          template?.status == "DRAFT"
            ? SegmentEvent.TEMPLATE_PUBLISHED
            : SegmentEvent.TEMPLATE_UNPUBLISHED;
        track(templateAction, {
          name: templateForm.name
        });
        await handleGetTemplate();
        showAlert(AlertType.SUCCESS, templateAction);
      }
    } catch (error) {
      showAlert(AlertType.DANGER, await extractErrorMessage(error));
    }
  };

  const handleSort = (field: string): void => {
    sort.sort(variants, field as keyof Variant);
  };

  const handleDeleteVariant = async (variant: Variant): Promise<boolean> => {
    try {
      const json: Response<void> = await api
        .delete(`product-template/${templateId}/variant/${variant.id}`)
        .json();
      return json.code === 200;
    } catch {
      return false;
    }
  };

  const deleteVariant = useDelete({
    getMessage: (data: Variant) => `Are you sure you want to permanently delete ${data.name}?`,
    handleDelete: handleDeleteVariant,
    refetch: handleGetTemplate
  });

  const deleteTemplate = useDelete({
    getMessage: (template: Template) =>
      `Are you sure you want to permanently delete ${template.name}?`,
    handleDelete: handleDeleteTemplate,
    refetch: () => navigate("/templates/published")
  });

  // UseEffects
  useEffect(() => {
    setFormIsValid({
      details:
        isNotEmpty(templateForm.name) &&
        isObjNotEmpty(templateForm.categories) &&
        (!!templateId || (!templateId && isNotEmpty(templateForm.productId))) &&
        isNotEmpty(templateForm.currency) &&
        isNumber(templateForm.price),
      images: isObjNotEmpty(templateForm.images) && templateForm.images.length > 0,
      description: isNotEmpty(templateForm.description)
    });
  }, [templateForm]);

  useEffect(() => {
    if (templateId) {
      handleGetTemplate();
    }
  }, [templateId]);

  useEffect(() => {
    handleGetProducts();
    handleGetBusinessCategories();
  }, []);

  return {
    templateForm,
    template,
    formIsValid,
    loaders: {
      ...loaders,
      fetchingProduct
    },
    error,
    handleFormChange,
    handleFormSubmit,
    templateId,
    products: products?.products || [],
    categories,
    handleRemoveCategory,
    activeStep,
    setActiveStep,
    handleImageUpload,
    handleSaveImages,
    handleSaveDescriptionAndPublish,
    handleRemoveImage,
    handleToggleDefault,
    isOpen,
    setIsOpen,
    handleTogglePublish,
    sort: { ...sort, handleSort },
    variants,
    deleteVariant,
    deleteTemplate
  };
};

export type UseTemplateType = ReturnType<typeof useTemplate>;
