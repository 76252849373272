import { FormChangeEvent, ProductComponentProps } from "types";
import React, { useState } from "react";
import Autocomplete from "components/Shared/Autocomplete";
import Cancel from "icons/Cancel";
import { Link } from "react-router-dom";
import Accordion from "components/Shared/Accordion";
import TextInput from "components/Shared/TextInput";
import InputGroup from "components/Shared/InputGroup";
import Info from "icons/Info";
import IconButton from "components/Shared/IconButton";
import ArrowLeft from "icons/ArrowLeft";
import ArrowRight from "icons/ArrowRight";
import Error from "components/Shared/Error";
import MeasurementUnit from "components/EditStoreItem/MeasurementUnit";
import Amount from "components/Shared/Amount";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import { getStoreItemName } from "hooks/useStoreItems/useStoreItems.utils";
import Tag from "components/Shared/Tag/Tag";

const Components = (props: ProductComponentProps): React.ReactElement => {
  const {
    storeItems,
    handleAttachComponent,
    handleDetachComponent,
    components,
    formulas,
    handleVariantComponentsChange,
    measurementUnits,
    loaders,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    variantsHaveChanges,
    productName
  } = props;
  const [activeKey, setActiveKey] = useState("");

  return (
    <form className="pb-40" onSubmit={handleFormSubmit} data-testid="componentForm">
      <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
        <Error error={error} />
        <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-0" data-testid="componentDesc">
          Add store items and their usage measurement for these product variants.
        </p>
        <div>
          <Autocomplete
            type="text"
            name="storeItems"
            onChange={(e: FormChangeEvent) => {
              if (e.target.name == "itemId" && e.target.value) {
                handleAttachComponent(e.target.value);
              }
            }}
            value=""
            label="Attach store item"
            data-testid="storeItems"
            dataIdName="itemId"
            data={
              storeItems?.items
                .filter(
                  (item) => !components.find((component) => component.storeItemId === item.id)
                )
                .map((item) => ({ id: item.id, name: getStoreItemName(item) })) || []
            }
            placeholder="Start typing to filter store items"
            defaultData={
              <span className="text-grey-50 text-sm">
                Can’t see what you are looking for?&nbsp;
                <Link to="/store/create" className="link inline-flex">
                  <b>Create a new store item here</b>
                </Link>
              </span>
            }
          />
          <div>
            <p className="font-bold">Active store items</p>
            <div className="flex mt-4 flex-wrap" data-testid="attachedComponents">
              {components.map((component) => (
                <Tag
                  key={component.id}
                  name={component.name}
                  onRemove={() => handleDetachComponent(component.id)}
                />
              ))}
            </div>
          </div>
        </div>
        <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-6" data-testid="">
          {components.length > 0
            ? `Set the store item measurements for ${productName} variants.`
            : "No store items attached."}
        </p>
        {formulas.map(
          (formula, formulaIndex): React.ReactElement => (
            <Accordion
              key={formula.variantId}
              itemId={formula.variantId}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              header={
                <div className="inline-flex items-center justify-between flex-1 pr-6">
                  <span>
                    <b data-testid="variantName">{formula.variantName}</b>
                    {formula.formulaParts.filter((part) => part.measurementValue == 0).length >
                      0 && (
                      <span
                        className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                        data-testid="emptyStoreItems">
                        <Info /> &nbsp;
                        {formula.formulaParts.filter((part) => part.measurementValue == 0).length}
                      </span>
                    )}
                  </span>
                  {!variantsHaveChanges[formula.variantId] && <Amount amount={formula.totalCost} />}
                </div>
              }>
              <div className="flex flex-col">
                {formula.formulaParts.map((part) => (
                  <div
                    className="flex justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                    key={part.componentId}
                    data-testid="part">
                    <span data-testid="partName" className="flex-1">
                      {part.name}
                    </span>
                    <div className="flex-1 max-w-xs">
                      <InputGroup>
                        <div className="w-auto flex-1">
                          <TextInput
                            type="number"
                            name="measurementValue"
                            inputSize="sm"
                            onChange={(e): void =>
                              handleVariantComponentsChange(e, formula.variantId, part.componentId)
                            }
                            value={part.measurementValue}
                            label="Measurement"
                            data-testid="measurementValue"
                            min={0}
                          />
                        </div>
                        <div className="w-16 md:w-20">
                          <MeasurementUnit
                            id="unit"
                            inputSize="sm"
                            name="measurementUnit"
                            value={part.measurementUnit}
                            required
                            onChange={(e): void =>
                              handleVariantComponentsChange(e, formula.variantId, part.componentId)
                            }
                            data-testid="measurementUnit"
                            className="bg-soft-grey"
                            label="Unit"
                            measurementUnit={part.measurementUnit}
                            measurementUnits={measurementUnits}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                ))}
                <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                  <div>
                    {formulaIndex > 0 && (
                      <IconButton
                        size="sm"
                        onClick={() => setActiveKey(formulas[formulaIndex - 1].variantId)}
                        icon={<ArrowLeft />}
                        data-testid="prev"
                        outlined>
                        Previous
                      </IconButton>
                    )}
                  </div>
                  <div>
                    {formulaIndex < formulas.length - 1 && (
                      <IconButton
                        size="sm"
                        onClick={() => setActiveKey(formulas[formulaIndex + 1].variantId)}
                        iconEnd={<ArrowRight />}
                        data-testid="next"
                        outlined>
                        Next variant
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
            </Accordion>
          )
        )}
      </div>
      <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-10">
        <AutosaveButton
          disabled={!hasUnsavedChanges}
          hasChanges={hasUnsavedChanges}
          type="submit"
          loading={loaders.savingComponents}
          data-testid="submitButton"
          className="w-60"
        />
      </div>
    </form>
  );
};

export default Components;
