import Autocomplete from "components/Shared/Autocomplete";
import Button from "components/Shared/Button";
import Dialog from "components/Shared/Dialog";
import Error from "components/Shared/Error";
import IconButton from "components/Shared/IconButton";
import { MarkdownEditor } from "components/Shared/MarkdownEditor/MarkdownEditor";
import Select from "components/Shared/Select";
import Stepper from "components/Shared/Stepper";
import Tag from "components/Shared/Tag/Tag";
import TextInput from "components/Shared/TextInput";
import Title from "components/Shared/Title";
import { Tooltip } from "components/Shared/Tooltip/Tooltip";
import ArrowLeft from "icons/ArrowLeft";
import { Star } from "icons/Star";
import { StarFilled } from "icons/StarFilled";
import { Upload } from "icons/Upload";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Link } from "react-router-dom";
import { CreateEditTemplateProps, FormChangeEvent } from "types";
import "components/Shared/MarkdownEditor/Markdown.css";

const fileTypes = ["jpg", "png", "gif", "jpeg", "svg"];
export const CreateEditTemplate = (props: CreateEditTemplateProps): React.ReactElement => {
  const {
    templateForm,
    handleFormChange,
    handleFormSubmit,
    formIsValid,
    error,
    loaders,
    templateId,
    products,
    categories,
    handleRemoveCategory,
    activeStep,
    setActiveStep,
    handleImageUpload,
    handleSaveImages,
    handleSaveDescriptionAndPublish,
    handleRemoveImage,
    handleToggleDefault,
    isOpen,
    setIsOpen,
    template
  } = props;

  return (
    <div className="bg-white rounded-lg py-form">
      <div className="form-xy">
        <Link
          to={templateId ? `/templates/published/${templateId}` : "/templates/published"}
          className="font-bold text-sm	inline-flex"
          data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to {templateId ? "template" : "published templates"}
        </Link>
        <Title className="my-8 text-center">
          {templateId ? "Edit" : "Create"} product template
        </Title>
        <Stepper
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          steps={["Template details", "Images", "Description"]}
        />
      </div>
      <div className="form-x">
        <Error error={error} />
      </div>
      {activeStep === "Template details" && (
        <form onSubmit={handleFormSubmit} data-testid="templateForm">
          <div data-testid="productName">
            {!templateId && (
              <div className="form-center">
                <Autocomplete
                  type="text"
                  name="categories"
                  onChange={(e: FormChangeEvent) => {
                    if (e.target.name == "productId" && e.target.value) {
                      handleFormChange(e);
                    }
                  }}
                  value=""
                  label="Select product to create template from"
                  data-testid="products"
                  dataIdName="productId"
                  data={products}
                  placeholder="Start typing to filter products"
                  disableNew
                />
              </div>
            )}
            <div className="form-center">
              <div>
                <TextInput
                  type="text"
                  name="name"
                  onChange={handleFormChange}
                  value={templateForm.name}
                  required
                  label="Name"
                  data-testid="nameInput"
                />
              </div>
            </div>
            <div className="form-center mb-5 flex gap-8">
              <div className="grow">
                <TextInput
                  type="number"
                  name="price"
                  id="price"
                  onChange={handleFormChange}
                  value={templateForm.price}
                  required
                  label="Price"
                  data-testid="priceInput"
                  min={0}
                />
              </div>
              <div className="grow">
                <Select
                  name="currency"
                  onChange={handleFormChange}
                  value={templateForm.currency}
                  required
                  label="Currency"
                  data-testid="currency">
                  <option value="" disabled>
                    Select currency
                  </option>
                  <option>NGN</option>
                </Select>
              </div>
            </div>
            <div className="form-center">
              <div>
                <Autocomplete
                  type="text"
                  name="categories"
                  onChange={(e: FormChangeEvent) => {
                    if (e.target.name == "categoryId" && e.target.value) {
                      handleFormChange(e);
                    }
                  }}
                  value=""
                  label="Add categories for template"
                  data-testid="categories"
                  dataIdName="categoryId"
                  data={categories.filter(
                    (category) => !templateForm.categories.find((c) => c === category.id)
                  )}
                  placeholder="Start typing to filter categories"
                  disableNew
                />
                <div className="flex mt-4 flex-wrap items-center" data-testid="">
                  <p className="font-bold mr-2">Categories</p>
                  {categories
                    .filter((category) => templateForm.categories.find((c) => c === category.id))
                    .map((category) => (
                      <Tag
                        key={category.id}
                        name={category.name}
                        onRemove={() => handleRemoveCategory(category.id)}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="text-center py-10">
              <Button
                type="submit"
                disabled={!formIsValid.details}
                className="w-60"
                data-testid="continue"
                loading={loaders.savingTemplate}>
                Add pictures
              </Button>
            </div>
          </div>
        </form>
      )}
      {activeStep === "Images" && (
        <div className="px-10">
          <div className="form-xy pr-12 md:pr-20 pt-0">
            <FileUploader handleChange={handleImageUpload} name="file" types={fileTypes} multiple>
              <div className="flex flex-col justify-center items-center p-6 py-10 border-1 border border-dark-grey border-dashed rounded-lg">
                <Upload className="mb-2" />
                <p className="mt-2 mb-2">Select your logo or drag and drop here</p>
                <p className="text-grey-50">Maximum 5MB in size.</p>
                <p className="text-grey-50">JPG, PNG, or GIF formats.</p>
                <p className="text-grey-50 mb-3">Recommended size: 300 x 300 pixels.</p>
                <IconButton outlined loading={loaders.uploadingImage}>
                  Select Images
                </IconButton>
              </div>
            </FileUploader>
          </div>
          <div>
            <p className="text-lg">Template Images</p>
            <div className="flex flex-wrap gap-4 mt-2">
              {templateForm.images.map((image, index) => (
                <div key={index} className="relative flex flex-col">
                  <img
                    src={image.url}
                    alt="template image"
                    className="w-32 h-32 object-cover rounded-md"
                  />
                  <span className="absolute -top-1.5 -right-1.5 text-primary">
                    <Tooltip title="Set as default image">
                      <span onClick={() => handleToggleDefault(index)}>
                        {image.isDefault ? <StarFilled /> : <Star />}
                      </span>
                    </Tooltip>
                  </span>
                  <span
                    data-testid="removeImage"
                    className="link w-6"
                    onClick={() => handleRemoveImage(index)}>
                    Remove
                  </span>
                </div>
              ))}
              {templateForm.images.length === 0 && (
                <p className="text-grey-50">No images uploaded</p>
              )}
            </div>
          </div>
          <div className="text-center py-10">
            <Button
              type="button"
              onClick={handleSaveImages}
              className="w-60"
              data-testid="continue"
              loading={loaders.savingImage}>
              Add description
            </Button>
            <Link to={`./../../${templateId}`} className="link mt-4 block">
              Continue later
            </Link>
          </div>
        </div>
      )}
      {activeStep === "Description" && (
        <div className="text-dark-grey" data-testid="">
          <div className="form-xy pt-0">
            <div className="border-soft-grey rounded-lg border-2 markdown">
              <MarkdownEditor
                markdown={templateForm.description}
                onChange={(markdown: string) =>
                  handleFormChange({
                    target: { name: "description", value: markdown }
                  } as FormChangeEvent)
                }
                placeholder="Describe the template in detail"
              />
            </div>
          </div>
          <div className="text-center py-10">
            <Button
              type="button"
              disabled={!formIsValid.description}
              onClick={handleSaveDescriptionAndPublish}
              className="w-60"
              data-testid="continue"
              loading={loaders.savingDesc}>
              {templateForm.images.length > 0 && template?.status == "unpublish"
                ? "Publish"
                : "Save description"}
            </Button>
            <Link to={`./../../${templateId}`} className="link mt-4 block">
              Continue later
            </Link>
          </div>
        </div>
      )}
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen} title="Paid template">
        <p className="pb-5">
          Your account has not been enabled for paid templates please contact us{" "}
        </p>
      </Dialog>
    </div>
  );
};
