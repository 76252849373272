import validator from "validator";

export const isNotEmpty = (text: string | undefined): boolean => {
  return text !== undefined && !validator.isEmpty(text.trim());
};

export const isEmail = (email: string): boolean => {
  return validator.isEmail(email.trim());
};

export const isNumber = (number: number | string | undefined): boolean => {
  return typeof number == "number" || (number !== undefined && validator.isNumeric(number));
};

export const isObjNotEmpty = <T>(obj: T[] | undefined): boolean => {
  return obj !== undefined && obj !== null && !!obj && obj.length > 0;
};