import React, { useEffect } from "react";
import { PublishedTemplates } from "components/PublishedTemplates/PublishedTemplates";
import { useTemplates } from "hooks/useTemplates/useTemplates";
import LoadingComponent from "components/Shared/Loading";

const PublishedTemplatesPage = (): React.ReactElement => {
  const templateLibraryProps = useTemplates();

  useEffect(() => {
    templateLibraryProps.handleGetPublishedTemplates();
  }, []);

  return (
    <LoadingComponent loading={templateLibraryProps.loaders.fetchingTemplates}>
      <PublishedTemplates {...templateLibraryProps} />
    </LoadingComponent>
  );
};

export default PublishedTemplatesPage;
