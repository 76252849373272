import api from "config/api";
import { ERROR_OCCURRED } from "constants/response";
import { useAlert } from "context/alert/AlertContext";
import { track } from "helpers/analytics";
import { isNotEmpty, isNumber } from "helpers/validate";
import { useDelete } from "hooks/shared/useDelete/useDelete";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormChangeEvent,
  ProductVariantLoaders,
  ProductTemplateVariantForm,
  ProductVariantForm,
  Response,
  ProductVariant
} from "types";
import { AlertType, SegmentEvent } from "types/enum";

export const useProductVariant = ({
  backURL,
  baseUri,
  addEvent,
  updateEvent,
  type
}: {
  type: "ProductTemplate" | "Product";
  baseUri: string;
  backURL: string;
  addEvent: SegmentEvent;
  updateEvent: SegmentEvent;
}) => {
  const navigate = useNavigate();
  const { variantId } = useParams();
  const { showAlert } = useAlert();

  // UseStates
  const [productVariantForm, setProductVariantForm] = useState<ProductVariantForm>({
    name: "",
    defaultSellingPrice: "0"
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [loaders, setLoaders] = useState<ProductVariantLoaders>({
    savingVariant: false,
    fetchingVariant: true
  });
  const [error, setError] = useState("");

  const handleProductVariantFormChange = (event: FormChangeEvent): void => {
    const { name, value } = event.target;
    setProductVariantForm((prev) => ({
      ...prev,
      [name]: value
    }));
    setError("");
  };

  const handleGetProductVariant = async (): Promise<void> => {
    try {
      const json: Response<ProductTemplateVariantForm | ProductVariantForm> = await api
        .get(`${baseUri}/variant/${variantId}`)
        .json();
      setProductVariantForm({ defaultSellingPrice: "0", ...json.data });
      setLoaders((prev) => ({ ...prev, fetchingVariant: false }));
    } catch {
      showAlert(AlertType.DANGER, "Variant details could not be fetched");
    }
  };

  const handleCreateProductVariant = async (): Promise<boolean> => {
    const requestData = {
      name: productVariantForm.name,
      defaultSellingPrice: +productVariantForm.defaultSellingPrice
    };

    try {
      const json: Response<void> = await api
        .post(`${baseUri}/variant`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 201;
      if (isSuccessfull) {
        track(addEvent, {
          defaultSellingPrice: +productVariantForm.defaultSellingPrice,
          name: productVariantForm.name,
          variantId
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleUpdateProductVariant = async (): Promise<boolean> => {
    const requestData = {
      name: productVariantForm.name,
      defaultSellingPrice: +productVariantForm.defaultSellingPrice
    };

    try {
      const json: Response<void> = await api
        .put(`${baseUri}/variant/${variantId}`, { json: requestData })
        .json();
      const isSuccessfull = json.code === 200;
      if (isSuccessfull) {
        track(updateEvent, {
          defaultSellingPrice: +productVariantForm.defaultSellingPrice,
          name: productVariantForm.name,
          variantId
        });
      }
      return isSuccessfull;
    } catch {
      return false;
    }
  };

  const handleProductVariantFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    setLoaders((prev) => ({ ...prev, savingVariant: true }));
    let result;
    if (variantId) {
      result = await handleUpdateProductVariant();
    } else {
      result = await handleCreateProductVariant();
    }
    setLoaders((prev) => ({ ...prev, savingVariant: false }));
    if (result) {
      showAlert(AlertType.SUCCESS);
      navigate(backURL);
    } else {
      setError(ERROR_OCCURRED);
    }
  };

  // UseEffects
  useEffect(() => {
    setFormIsValid(
      isNotEmpty(productVariantForm.name) && isNumber(productVariantForm.defaultSellingPrice)
    );
  }, [productVariantForm]);

  useEffect(() => {
    if (variantId) {
      handleGetProductVariant();
    }
  }, [variantId]);

  return {
    formIsValid,
    loaders,
    error,
    variantId,
    productVariantForm,
    handleProductVariantFormChange,
    handleProductVariantFormSubmit,
    backURL,
    type
  };
};

export type UseProductVariantType = ReturnType<typeof useProductVariant>;
