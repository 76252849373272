import React from "react";

export const NavLibrary = (): React.ReactElement => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.0618 15.4165C3.70733 15.4165 3.38959 15.3102 3.10858 15.0976C2.82757 14.8851 2.63715 14.606 2.53731 14.2605L0.457504 6.79781C0.390202 6.54877 0.433391 6.31881 0.58707 6.10792C0.740764 5.89701 0.949415 5.79156 1.21302 5.79156H5.34403L8.84064 0.55674C8.91356 0.445688 9.00723 0.359311 9.12167 0.297609C9.23609 0.235921 9.3606 0.205078 9.4952 0.205078C9.62982 0.205078 9.75434 0.237329 9.86876 0.301831C9.98318 0.366333 10.0769 0.45411 10.1498 0.565162L13.6296 5.79156H17.7875C18.0511 5.79156 18.2612 5.89701 18.4176 6.10792C18.5741 6.31881 18.6159 6.54877 18.543 6.79781L16.4464 14.2605C16.3465 14.606 16.1561 14.8851 15.8751 15.0976C15.5941 15.3102 15.2764 15.4165 14.9219 15.4165H4.0618ZM4.01469 14.104H14.9858C15.0475 14.104 15.1008 14.0858 15.1457 14.0493C15.1906 14.0129 15.2214 13.9638 15.2382 13.9021L17.1128 7.10404H1.88776L3.76227 13.9021C3.7791 13.9638 3.80995 14.0129 3.85482 14.0493C3.8997 14.0858 3.95298 14.104 4.01469 14.104ZM9.50026 11.9165C9.8581 11.9165 10.1663 11.7872 10.4249 11.5287C10.6834 11.2701 10.8127 10.9619 10.8127 10.604C10.8127 10.2462 10.6834 9.93799 10.4249 9.67942C10.1663 9.42085 9.8581 9.29156 9.50026 9.29156C9.14241 9.29156 8.83421 9.42085 8.57565 9.67942C8.31707 9.93799 8.18778 10.2462 8.18778 10.604C8.18778 10.9619 8.31707 11.2701 8.57565 11.5287C8.83421 11.7872 9.14241 11.9165 9.50026 11.9165ZM6.93583 5.79156H12.0512L9.4868 1.93145L6.93583 5.79156Z"
      fill="currentColor"
    />
  </svg>
);
