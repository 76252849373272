import IconButton from "components/Shared/IconButton";
import Plus from "icons/Plus";
import React from "react";
import { ActivitiesProps } from "types";
import Amount from "components/Shared/Amount";
import Edit from "icons/Edit";
import Delete from "icons/Delete";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import Table from "components/Shared/Table";
import DateFilter from "components/Shared/DateFilter";
import Disclosure from "components/Shared/Disclosure";
import { formatMonthRange } from "helpers/date";
import Dialog from "components/Shared/Dialog";
import Error from "components/Shared/Error";
import TextInput from "components/Shared/TextInput";
import Button from "components/Shared/Button";
import Dropdown from "components/Shared/Dropdown";
import Kebeb from "icons/Kebeb";

const Activities = (props: ActivitiesProps): React.ReactElement => {
  const {
    activities,
    dateRange,
    handleDateRangeChange,
    deleteActivity,
    handleFormSubmit,
    handleFormChange,
    error,
    activityForm,
    loaders,
    setActivity,
    activity,
    resetActivityForm,
    setIsOpen,
    isOpen
  } = props;

  return (
    <div className="">
      <div className="bg-white sm:rounded-lg p-4 lg:p-8 mt-2 md:mt-7">
        <div className="flex flex-col-reverse sm:flex-row justify-between items-center pb-6">
          <DateFilter
            dateRange={dateRange}
            handleDateRangeChange={(date): void => handleDateRangeChange(date)}
          />
          <span
            data-testid="createActivity"
            className="mb-4 sm:mb-0 cursor-pointer"
            onClick={(): void => {
              resetActivityForm();
              setIsOpen(true);
            }}>
            <IconButton icon={<Plus />}>Add new activity</IconButton>
          </span>
        </div>
        <div className="hidden sm:block">
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadData>Activity</Table.HeadData>
                <Table.HeadData>Total cost</Table.HeadData>
                <Table.HeadData>Cost per hour</Table.HeadData>
                <Table.HeadData />
              </Table.Row>
            </Table.Head>
            <Table.Body className="text-dark-grey" data-testid="activities">
              {activities.map(
                (activity): React.ReactElement => (
                  <Table.Row
                    key={activity.activityId}
                    className="rounded-lg"
                    data-testid="activity">
                    <Table.Data data-testid="activityName">{activity.name}</Table.Data>
                    <Table.Data data-testid="activityTotalCost">
                      <Amount amount={activity.totalCost} />
                    </Table.Data>
                    <Table.Data data-testid="activityCostPerHour" className="font-bold">
                      <Amount amount={activity.costPerHour} />
                    </Table.Data>
                    <Table.Data>
                      {/* TODO use dropdown */}
                      {activity.isProductionActivity && (
                        <Dropdown
                          menu={[
                            {
                              text: "Edit",
                              icon: <Edit />,
                              onClick: () => {
                                setActivity(activity);
                                setIsOpen(true);
                              }
                            },
                            {
                              text: "Delete",
                              icon: <Delete />,
                              onClick: () => deleteActivity.handleConfirmDelete(activity)
                            }
                          ]}>
                          <Kebeb />
                        </Dropdown>
                      )}
                    </Table.Data>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="block sm:hidden mt-4">
          <div className="flex">
            <span className="font-bold text-grey-50 w-2/5 px-4">Name</span>
            <span className="font-bold text-grey-50 w-2/5 px-4">Total cost</span>
            <span className="w-1/5"></span>
          </div>
          {activities.map(
            (activity): React.ReactElement => (
              <Disclosure key={activity.activityId} data-testid="activityMobile">
                <p>
                  <span className="font-bold w-1/2 inline-flex" data-testid="activityName">
                    {activity.name}
                  </span>
                  <span className="font-bold w-1/2 inline-flex" data-testid="activityTotalCost">
                    <Amount amount={activity.totalCost} />
                  </span>
                </p>
                <div className="flex flex-col">
                  <small className="mt-2" data-testid="activityCostPerHour">
                    <span className="font-bold w-24 inline-flex">Cost per hour:</span>{" "}
                    {activity.costPerHour}
                  </small>
                  {activity.isProductionActivity && (
                    <span className="flex">
                      <button
                        data-testid="edit"
                        onClick={(): void => {
                          setActivity(activity);
                          setIsOpen(true);
                        }}
                        className="mobile-link">
                        <Edit className="mobile-icon" />
                        <span>Edit</span>
                      </button>
                      <button
                        data-testid="confirmDelete"
                        onClick={(): void => deleteActivity.handleConfirmDelete(activity)}
                        className="text-red-500 mobile-link">
                        <Delete className="mobile-icon" />
                        <span>Delete</span>
                      </button>
                    </span>
                  )}
                </div>
              </Disclosure>
            )
          )}
        </div>
        {activities.length == 0 && (
          <div data-testid="noActivities" className="p-4 md:px-6">
            No activities created for {formatMonthRange(dateRange)}
          </div>
        )}
      </div>
      <ConfirmationDialog
        message={deleteActivity.deleteConfirmationMessage}
        handleContinue={deleteActivity.handleDeleteConfirmation}
        handleCancel={deleteActivity.handleDeleteCancellation}
      />
      <Dialog
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={`${activity ? "Update" : "Create"} Activity`}>
        <form onSubmit={handleFormSubmit} className="" data-testid="activityForm">
          <Error error={error} />
          <div>
            <TextInput
              type="text"
              name="name"
              id="name"
              value={activityForm.name}
              required={true}
              onChange={handleFormChange}
              label="Activity name"
              data-testid="nameInput"
            />
          </div>
          <Button
            type="submit"
            data-testid="activitySubmitButton"
            disabled={!activityForm.name}
            loading={loaders.savingActivity}
            className="w-full mt-6">
            Save
          </Button>
        </form>
      </Dialog>
    </div>
  );
};

export default Activities;
