import React from "react";
import Cancel from "icons/Cancel";

interface TagProps {
  name: string;
  onRemove: () => void;
}

const Tag: React.FC<TagProps> = ({ name, onRemove }) => {
  return (
    <span
      data-testid="attachedComponent"
      className="border border-grey-30 text-dark-grey cursor-pointer rounded-md py-2 px-4 flex items-center space-x-2 font-bold mb-2 mr-3">
      <span>{name}</span>
      <Cancel data-testid="removeIcon" width={20} height={20} onClick={onRemove} />
    </span>
  );
};

export default Tag;
