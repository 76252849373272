import { name } from "country-emoji";
import { TemplateSummaryItem } from "types";

export const mockTemplates: TemplateSummaryItem[] = [
  {
    price: 0,
    name: "Vanilla Cake",
    categories: [{ id: "", name: "Cake" }],
    installs: 23,
    status: "DRAFT",
    thumbnailUrl: "http://localhost:3010/images/templates/vanilla-cake.png",
    owner: "Shalewa Uti",
    id: ""
  },
  {
    price: 500,
    name: "Chocolate Cake",
    categories: [{ id: "", name: "Cake" }],
    installs: 23,
    status: "PUBLISHED",
    thumbnailUrl: "http://localhost:3010/images/templates/chocolate-cake.png",
    owner: "Shalewa Uti",
    id: ""
  },
  {
    price: 0,
    name: "Red Velvet",
    categories: [{ id: "", name: "Cake" }],
    installs: 23,
    status: "PUBLISHED",
    thumbnailUrl: "http://localhost:3010/images/templates/red-velvet.png",
    owner: "Shalewa Uti",
    id: ""
  },
  {
    price: 0,
    name: "Peanut Butter Cookies",
    categories: [{ id: "", name: "Cookies" }],
    installs: 23,
    status: "PUBLISHED",
    thumbnailUrl: "http://localhost:3010/images/templates/peanut-butter-cookies.png",
    owner: "Shalewa Uti",
    id: ""
  },
  {
    price: 500,
    name: "Vanilla Cookies",
    categories: [{ id: "", name: "Cookies" }],
    installs: 23,
    status: "PUBLISHED",
    thumbnailUrl: "http://localhost:3010/images/templates/vanilla-cookies.png",
    owner: "Shalewa Uti",
    id: ""
  },
  {
    price: 0,
    name: "Chocolate Chip Cookies",
    categories: [{ id: "", name: "Cookies" }],
    installs: 23,
    status: "PUBLISHED",
    thumbnailUrl: "http://localhost:3010/images/templates/chocolate-chip-cookies.png",
    owner: "Shalewa Uti",
    id: ""
  }
];
