import { MDXEditorMethods, MDXEditor, MDXEditorProps } from "@mdxeditor/editor";
import { useEffect, useRef } from "react";
import "@mdxeditor/editor/style.css";
import { ALL_PLUGINS } from "./MarkdownEditor.utils";
import { undefinedToString } from "helpers/string";
import React from "react";

export type MarkdownEditorProps = MDXEditorProps;

export const MarkdownEditor = ({
  onChange,
  plugins = ALL_PLUGINS,
  markdown,
  ...props
}: MarkdownEditorProps): React.ReactElement => {
  const mdEditor = useRef<MDXEditorMethods>(null);

  useEffect(() => {
    mdEditor.current?.setMarkdown(undefinedToString(markdown));
  }, [markdown]);

  return (
    <MDXEditor
      ref={mdEditor}
      data-testid="editor"
      markdown=""
      onChange={onChange}
      plugins={plugins}
      className="min-h-[14rem]"
      {...props}
    />
  );
};
