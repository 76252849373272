import React, { SVGProps } from "react";

export const Upload = (props?: SVGProps<SVGSVGElement>): React.ReactElement => {
  return (
    <svg
      {...props}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32.5 32L24.5 24L16.5 32"
        stroke="#1C252C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 24V42"
        stroke="#1C252C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2789 36.78C43.2296 35.7165 44.7706 34.0337 45.6587 31.9972C46.5468 29.9607 46.7314 27.6864 46.1834 25.5334C45.6353 23.3803 44.3859 21.471 42.6323 20.1069C40.8786 18.7427 38.7207 18.0014 36.4989 18H33.9789C33.3736 15.6585 32.2453 13.4846 30.6788 11.642C29.1124 9.79927 27.1486 8.33567 24.9351 7.36118C22.7216 6.3867 20.316 5.92669 17.8992 6.01573C15.4823 6.10478 13.1171 6.74057 10.9813 7.8753C8.84552 9.01003 6.99477 10.6142 5.56819 12.5671C4.14161 14.5201 3.17632 16.771 2.7449 19.1508C2.31348 21.5305 2.42715 23.977 3.07737 26.3065C3.72759 28.636 4.89743 30.7877 6.49894 32.6"
        stroke="#1C252C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 32L24.5 24L16.5 32"
        stroke="#1C252C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
