import { TemplateCard } from "components/PublishedTemplates/TemplateCard/TemplateCard";
import { mockTemplates } from "components/PublishedTemplates/templateData";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";
import Select from "components/Shared/Select";
import TextInput from "components/Shared/TextInput";
import Title from "components/Shared/Title/Title";
import Search from "icons/Search";
import React from "react";
import ArrowRight from "icons/ArrowRight";

export const TemplateLibraryCategory = (): React.ReactElement => {
  return (
    <div>
      <Title className="pt-2 pb-6 px-4 md:px-0">
        Product Templates
        <InfoTooltip>These are the templates available for you.</InfoTooltip>
      </Title>
      <div className="flex flex-col-reverse sm:flex-row justify-between items-start sm:items-center px-4 md:px-0">
        <div className="flex gap-4 items-center">
          <div className="w-40">
            <Select simple inputSize="sm" name="businessType" label="" defaultValue="">
              <option value="">Business Type</option>
              <option value="Bakery">Bakery</option>
              <option value="Photography">Photography</option>
            </Select>
          </div>
          <div className="w-32">
            <Select simple inputSize="sm" name="category" label="" defaultValue="">
              <option value="">Category</option>
              <option value="Cakes">Cakes</option>
              <option value="Cookies">Cookies</option>
            </Select>
          </div>
          <div className="w-32">
            <Select simple inputSize="sm" name="price" label="" defaultValue="">
              <option value="">Price</option>
              <option value="1">N1 - N100</option>
              <option value="2">N101 - N200</option>
              <option value="3">{">N200"}</option>
            </Select>
          </div>
          <span className="link mb-2">Clear filters</span>
        </div>

        <div className="w-60">
          <span>
            <TextInput
              simple
              inputSize="sm"
              type="text"
              name="search"
              label=""
              className="!pt-0"
              placeholder="Search"
              prepend={
                <span className="relative -left-1 -top-[0.6rem] text-grey-50">
                  <Search width={20} height={20} />
                </span>
              }
            />
          </span>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 bg-white lg:p-8 p-4 rounded-lg  mt-2">
        <div className="flex items-center gap-2">
          <h4 className="font-semibold text-lg">Cakes</h4>
          <span className="left-3 bottom-3 text-grey-60 px-2 py-1 rounded bg-soft-grey font-medium text-xs">
            56 Templates
          </span>
        </div>
        <div></div>
        <div className="link text-right flex justify-end">
          See More Cakes <ArrowRight />
        </div>
        {mockTemplates.slice(0, 3).map((template, index) => (
          <TemplateCard key={index} template={template} library />
        ))}
        <div className="flex items-center gap-2">
          <h4 className="font-semibold text-lg">Cookies</h4>
          <span className="left-3 bottom-3 text-grey-60 px-2 py-1 rounded bg-soft-grey font-medium text-xs">
            56 Templates
          </span>
        </div>
        <div></div>
        <div className="link text-right flex justify-end">
          See More Cookies <ArrowRight />
        </div>
        {mockTemplates.slice(3, 6).map((template, index) => (
          <TemplateCard key={index} template={template} library />
        ))}
      </div>
    </div>
  );
};
