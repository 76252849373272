import React, { useEffect } from "react";
import { TemplateLibraryCategory } from "components/TemplateLibrary/TemplateLibraryCategory";
import { useTemplates } from "hooks/useTemplates/useTemplates";

const TemplateLibraryPage = (): React.ReactElement => {
  const templateLibraryProps = useTemplates();

  useEffect(() => {
    templateLibraryProps.handleGetPublishedTemplates();
  }, []);

  return <TemplateLibraryCategory />;
};

export default TemplateLibraryPage;
