import React from "react";
import { Navigate, createBrowserRouter } from "react-router-dom";
import LandingPage from "pages/landing";
import SignUpPage from "pages/signup";
import SignInPage from "pages/signin";
import PostAuthPage from "pages/post-auth";
import SSOAuthPage from "pages/sso-auth";
import OnboardingPage from "pages/onboarding";
import OnboardingMemberPage from "pages/onboarding/member";
import OnboardingBusinessPage from "pages/onboarding/business";
import OverviewPage from "pages/overview";
import { appState } from "config/store";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "config/firebase";
import { none } from "@hookstate/core";
import DashboardLayout from "components/Layout/Dashboard";
import Page from "components/Layout/Page";
import ExpensesPage from "pages/expense/index";
import CreateExpensePage from "pages/expense/create";
import EditExpensePage from "pages/expense/_id/edit";
import ProductsPage from "pages/product/index";
import CreateProductPage from "pages/product/create";
import EditProductPage from "pages/product/_id/edit";
import ViewProductPage from "pages/product/_id/index";
import ConfigureProductPage from "pages/product/_id/configure";
import PostCreateProductPage from "pages/product/_id/post-create";
import CreateProductVariantPage from "pages/product/_id/variant/create";
import EditProductVariantPage from "pages/product/_id/variant/_id/edit";
import OrdersPage from "pages/order/index";
import CreateOrderPage from "pages/order/create";
import ViewOrderPage from "pages/order/_id/index";
import EditOrderPage from "pages/order/_id/edit";
import OrderProductionPage from "pages/order/_id/production";
import OrderInvoicePage from "pages/order/_id/invoice";
import StorePage from "pages/store/index";
import CreateStoreItemPage from "pages/store/create";
import CreateProductStoreItemPage from "pages/store/create-product";
import EditStoreItemPage from "pages/store/_id/index";
import ErrorPage from "pages/error";
import { autoRefreshToken } from "helpers/auth";
import SettingsPage from "pages/settings/index";
import MembersPage from "pages/settings/members/index";
import CreateMemberPage from "pages/settings/members/create";
import EditMemberPage from "pages/settings/members/_id/index";
import PaymentMethodsPage from "pages/settings/payments/index";
import JoinPage from "pages/join";
import ViewInvoicePage from "pages/invoice";
import NotificationsPage from "pages/notifications";
import OrderTemplatesPage from "pages/order-template/index";
import EditOrderTemplatesPage from "pages/order-template/_id/index";
import CreateOrderTemplatesPage from "pages/order-template/create";
import { FeatureFlag } from "types/enum";
import { NONE } from "constants/general";
import TemplateLibraryPage from "pages/template/library";
import PublishedTemplatesPage from "pages/template/published";
import PurchasedTemplatesPage from "pages/template/purchased";
import TemplateDetailPage from "pages/template/_id/index";
import CreateTemplatePage from "pages/template/create";
import { PostCreateTemplatePage } from "pages/template/post-create";
import { EditTemplatePage } from "pages/template/_id/edit";
import { ConfigureTemplatePage } from "pages/template/_id/configure";
import { TemplateVariantsPage } from "pages/template/_id/variant";
import { CreateTemplateVariantPage } from "pages/template/_id/variant/create";
import { EditTemplateVariantPage } from "pages/template/_id/variant/_id/edit";

const expensesSubPages = [
  {
    path: "",
    element: (
      <Page title="Expenses" feature={FeatureFlag.EXPENSES}>
        <ExpensesPage />
      </Page>
    )
  },
  {
    path: "create",
    element: (
      <Page title="Create Expense" feature={FeatureFlag.EXPENSES}>
        <CreateExpensePage />
      </Page>
    )
  },
  {
    path: ":expenseId/edit",
    element: (
      <Page title="Edit Expense" feature={FeatureFlag.EXPENSES}>
        <EditExpensePage />
      </Page>
    )
  }
];

const productsSubPages = [
  {
    path: "",
    element: (
      <Page title="Products" feature={FeatureFlag.PRODUCTS}>
        <ProductsPage />
      </Page>
    )
  },
  {
    path: "create",
    element: (
      <Page title="Create Product" feature={FeatureFlag.PRODUCTS}>
        <CreateProductPage />
      </Page>
    )
  },
  {
    path: ":productId",
    element: (
      <Page title="View Product" feature={FeatureFlag.PRODUCTS}>
        <ViewProductPage />
      </Page>
    )
  },
  {
    path: ":productId/post-create",
    element: (
      <Page title="Post Create Product" feature={FeatureFlag.PRODUCTS}>
        <PostCreateProductPage />
      </Page>
    )
  },
  {
    path: ":productId/edit",
    element: (
      <Page title="Edit Product" feature={FeatureFlag.PRODUCTS}>
        <EditProductPage />
      </Page>
    )
  },
  {
    path: ":productId/configure",
    element: (
      <Page title="Configure Product" feature={FeatureFlag.PRODUCTS}>
        <ConfigureProductPage />
      </Page>
    )
  },
  {
    path: ":productId/variants",
    children: [
      {
        path: "create",
        element: (
          <Page title="Create Product Variant" feature={FeatureFlag.PRODUCTS}>
            <CreateProductVariantPage />
          </Page>
        )
      },
      {
        path: ":variantId/edit",
        element: (
          <Page title="Edit Product Variant" feature={FeatureFlag.PRODUCTS}>
            <EditProductVariantPage />
          </Page>
        )
      }
    ]
  }
];

const ordersSubPages = [
  {
    path: "",
    element: (
      <Page title="Orders" feature={FeatureFlag.ORDERS}>
        <OrdersPage />
      </Page>
    )
  },
  {
    path: "create",
    element: (
      <Page title="Create Order" feature={FeatureFlag.ORDERS}>
        <CreateOrderPage />
      </Page>
    )
  },
  {
    path: ":orderId",
    element: (
      <Page title="View Order" feature={FeatureFlag.ORDERS}>
        <ViewOrderPage />
      </Page>
    )
  },
  {
    path: ":orderId/edit",
    element: (
      <Page title="Edit Order" feature={FeatureFlag.ORDERS}>
        <EditOrderPage />
      </Page>
    )
  },
  {
    path: ":orderId/production",
    element: (
      <Page title="Order Production" feature={FeatureFlag.ORDERS}>
        <OrderProductionPage />
      </Page>
    )
  },
  {
    path: ":orderId/invoice",
    element: (
      <Page title="Order Invoice" feature={FeatureFlag.ORDERS}>
        <OrderInvoicePage />
      </Page>
    )
  }
];

const orderTemplatesSubPages = [
  {
    path: "",
    element: (
      <Page title="Order Templates" feature={FeatureFlag.ORDERS}>
        <OrderTemplatesPage />
      </Page>
    )
  },
  {
    path: "create",
    element: (
      <Page title="Create Order Templates" feature={FeatureFlag.ORDERS}>
        <CreateOrderTemplatesPage />
      </Page>
    )
  },
  {
    path: ":templateId",
    element: (
      <Page title="Edit Order Templates" feature={FeatureFlag.ORDERS}>
        <EditOrderTemplatesPage />
      </Page>
    )
  }
];

const storeSubPages = [
  {
    path: "",
    element: (
      <Page title="Store" feature={FeatureFlag.STORE}>
        <StorePage />
      </Page>
    )
  },
  {
    path: "create",
    element: (
      <Page title="Create Raw Store Item" feature={FeatureFlag.STORE}>
        <CreateStoreItemPage />
      </Page>
    )
  },
  {
    path: "create-product",
    element: (
      <Page title="Create Product Store Item" feature={FeatureFlag.STORE}>
        <CreateProductStoreItemPage />
      </Page>
    )
  },
  {
    path: ":storeId/edit",
    element: (
      <Page title="Edit Store Item" feature={FeatureFlag.STORE}>
        <EditStoreItemPage />
      </Page>
    )
  }
];

const settingsSubPages = [
  {
    path: "",
    element: (
      <Page title="Settings" feature={FeatureFlag.SETTINGS}>
        <SettingsPage />
      </Page>
    )
  },
  {
    path: "members",
    element: (
      <Page title="Manage Users" feature={FeatureFlag.SETTINGS}>
        <MembersPage />
      </Page>
    )
  },
  {
    path: "members/create",
    element: (
      <Page title="Create Member" feature={FeatureFlag.SETTINGS}>
        <CreateMemberPage />
      </Page>
    )
  },
  {
    path: "members/:memberId/edit",
    element: (
      <Page title="Edit Member" feature={FeatureFlag.SETTINGS}>
        <EditMemberPage />
      </Page>
    )
  },
  {
    path: "payments",
    element: (
      <Page title="Manage Payment Methods" feature={FeatureFlag.SETTINGS}>
        <PaymentMethodsPage />
      </Page>
    )
  }
];

const templatesSubPages = [
  //FeatureFlag.TEMPLATES
  {
    path: "library",
    element: (
      <Page title="Template Library" feature={"none"}>
        <TemplateLibraryPage />
      </Page>
    )
  },
  {
    path: "published",
    children: [
      {
        path: "",
        element: (
          <Page title="Published Templates" feature={"none"}>
            <PublishedTemplatesPage />
          </Page>
        )
      },
      {
        path: ":templateId/post-create",
        element: (
          <Page title="Post Create Template" feature={"none"}>
            <PostCreateTemplatePage />
          </Page>
        )
      },
      {
        path: ":templateId/edit",
        element: (
          <Page title="Edit Template" feature={"none"}>
            <EditTemplatePage />
          </Page>
        )
      },
      {
        path: ":templateId/configure",
        element: (
          <Page title="Configure Template" feature={"none"}>
            <ConfigureTemplatePage />
          </Page>
        )
      },
      {
        path: ":templateId/variants",
        element: (
          <Page title="Template Variants" feature={"none"}>
            <TemplateVariantsPage />
          </Page>
        )
      },
      {
        path: ":templateId/variants/create",
        element: (
          <Page title="Create Template Variant" feature={"none"}>
            <CreateTemplateVariantPage />
          </Page>
        )
      },
      {
        path: ":templateId/variants/:variantId/edit",
        element: (
          <Page title="Edit Template Variant" feature={"none"}>
            <EditTemplateVariantPage />
          </Page>
        )
      },
      {
        path: "create/:templateId?",
        element: (
          <Page title="Create Template" feature={"none"}>
            <CreateTemplatePage />
          </Page>
        )
      },
      {
        path: ":templateId",
        element: (
          <Page title="Template Detail" feature={"none"}>
            <TemplateDetailPage />
          </Page>
        )
      }
    ]
  },
  {
    path: "purchased",
    element: (
      <Page title="Purchased Templates" feature={"none"}>
        <PurchasedTemplatesPage />
      </Page>
    )
  }
];

export const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <Page title="Landing" feature={NONE}>
            <LandingPage />
          </Page>
        )
      },
      {
        path: "/signin",
        element: (
          <Page title="Sign In" feature={NONE}>
            <SignInPage />
          </Page>
        )
      },
      {
        path: "/signup",
        element: (
          <Page title="Sign Up" feature={NONE}>
            <SignUpPage />
          </Page>
        )
      },
      {
        path: "/sso-auth",
        element: (
          <Page title="Single Sign On" feature={NONE}>
            <SSOAuthPage />
          </Page>
        )
      },
      {
        path: "/join/:inviteId",
        element: (
          <Page title="Join Bloompulse" feature={NONE}>
            <JoinPage />
          </Page>
        )
      },
      {
        path: "/order/invoice/:orderId",
        element: (
          <Page title="View Order Invoice" feature={NONE}>
            <ViewInvoicePage />
          </Page>
        )
      },
      {
        loader: () => {
          return onAuthStateChanged(auth, async (user) => {
            if (user) {
              autoRefreshToken(user);
            } else {
              window.location.href = "/signin";
              appState.accessToken.set(none);
            }
          });
        },
        children: [
          {
            path: "/post-auth",
            element: <PostAuthPage />
          },
          {
            path: "/onboarding",
            element: (
              <Page title="Onboarding" feature={NONE}>
                <OnboardingPage />
              </Page>
            )
          },
          {
            path: "/onboarding/member",
            element: (
              <Page title="Onboarding Member" feature={NONE}>
                <OnboardingMemberPage />
              </Page>
            )
          },
          {
            path: "/onboarding/business",
            element: (
              <Page title="Onboarding Business" feature={NONE}>
                <OnboardingBusinessPage />
              </Page>
            )
          },
          {
            element: <DashboardLayout />,
            children: [
              {
                path: "/overview",
                element: (
                  <Page title="Overview" feature={FeatureFlag.DASHBOARD}>
                    <OverviewPage />
                  </Page>
                )
              },
              {
                path: "/expenses",
                children: expensesSubPages
              },
              {
                path: "/products",
                children: productsSubPages
              },
              {
                path: "/sales",
                children: [
                  { path: "", element: <Navigate to="orders" /> },
                  { path: "orders", children: ordersSubPages },
                  { path: "templates", children: orderTemplatesSubPages }
                ]
              },
              {
                path: "/store",
                children: storeSubPages
              },
              {
                path: "/settings",
                children: settingsSubPages
              },
              {
                path: "/notifications",
                element: (
                  <Page title="Notifications" feature={NONE}>
                    <NotificationsPage />
                  </Page>
                )
              },
              {
                path: "/templates",
                children: [{ path: "", element: <Navigate to="library" /> }, ...templatesSubPages]
              }
            ]
          }
        ]
      }
    ]
  }
]);
