export const clean = (value: string | undefined): string => {
  return (value || "").toLowerCase().replace(/\s+/g, " ").trim();
};

export const capitalize = (str: string): string => {
  return str
    .split(" ")
    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
    .join(" ");
};

export const capitalizeFirstLetter = (str: string): string => {
  return str[0].toUpperCase() + str.substr(1).toLowerCase();
};

export const undefinedToString = (text?: string | null): string => text ?? "";
