import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import React, { ReactNode, useRef } from "react";

export type TooltipProps = {
  children: ReactNode;
  title: ReactNode;
};
export const Tooltip = ({ children, title }: TooltipProps): React.ReactElement => {
  const triggerRef = useRef<HTMLButtonElement>(null);

  const timeOutRef = useRef<NodeJS.Timeout>();
  const timeoutDuration = 120;

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    !isOpen && triggerRef.current?.click();
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && document.activeElement === triggerRef.current && triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className="relative inline-block pl-[2px] top-[4px]">
      {({ open }: { open: boolean }) => (
        <span
          className="relative inline-block"
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}>
          <PopoverButton className="outline-none" ref={triggerRef}>
            {children}
          </PopoverButton>
          <PopoverPanel anchor="bottom" className="flex flex-col">
            <span className="ml-2 px-2 md:px-3 py-1 border text-white bg-grey-60 rounded-md shadow-md border-grey-60 text-[0.7rem] leading-4 md:text-xs font-normal max-w-[300px] top-full mt-1 z-20 text-left">
              {title}
            </span>
          </PopoverPanel>
        </span>
      )}
    </Popover>
  );
};
