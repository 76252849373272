import { TemplateVariants } from "components/TemplateVariants/TemplateVariants";
import LoadingComponent from "components/Shared/Loading";
import { useTemplate } from "hooks/useTemplate/useTemplate";
import React from "react";

export const TemplateVariantsPage = (): React.ReactElement => {
  const { sort, template, loaders, deleteVariant } = useTemplate();

  return (
    <LoadingComponent loading={loaders.fetchingTemplate}>
      {template && (
        <TemplateVariants sort={sort} template={template} deleteVariant={deleteVariant} />
      )}
    </LoadingComponent>
  );
};
