import cx from "classnames";
import Amount from "components/Shared/Amount";
import React from "react";
import { Link } from "react-router-dom";
import { TemplateSummaryItem } from "types";

export const TemplateCard = ({
  template,
  library
}: {
  template: TemplateSummaryItem;
  library?: boolean;
}): React.ReactElement => {
  return (
    <Link to={`/templates/published/${template.id}`} className="cursor-pointer">
      <div className="h-40 w-full relative">
        <img
          src={template.thumbnailUrl || "/images/thumbnail.jpg"}
          alt={template.name}
          className="w-full h-full rounded-lg object-contain"
        />
        <span
          className={cx(
            "absolute left-3 top-3 text-white text-xs px-2 py-1 rounded font-medium",
            template.price && "bg-gradient-to-r from-primary to-secondary",
            !template.price && "bg-grey-30"
          )}>
          {template.price ? <Amount amount={template.price} /> : "FREE"}
        </span>
        {template.status == "DRAFT" && !library && (
          <span className="absolute left-3 bottom-3 text-white text-xs px-2 py-1 rounded bg-grey-60 font-medium">
            DRAFT
          </span>
        )}
      </div>
      <div className="flex justify-between py-4">
        <div>
          <p className="text-sm md:text-base font-semibold">{template.name}</p>
          <p className="text-sm font-semibold text-grey-60">
            {library
              ? `By:  ${template.owner}`
              : template.categories.map((category) => category.name).join(", ")}
          </p>
        </div>
        <p className="whitespace-nowrap">{`💚 ${template.installs}`}</p>
      </div>
    </Link>
  );
};
