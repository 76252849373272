import { Feature, NavMenuItem } from "types";
import React from "react";
import { ImmutableArray } from "@hookstate/core";
import { FeatureFlag } from "types/enum";
import { NavStore } from "icons/NavStore";
import { NavProduct } from "icons/NavProduct";
import { NavSale } from "icons/NavSale";
import { NavExpense } from "icons/NavExpense";
import { NavNotification } from "icons/NavNotification";
import { NavSetting } from "icons/NavSetting";
import { NavOrder } from "icons/NavOrder";
import { NavOrderTemplate } from "icons/NavOrderTemplate";
import { NavTemplate } from "icons/NavTemplate";
import { NavLibrary } from "icons/NavLibrary";
import { NavPurchased } from "icons/NavPurchased";

export const getNavMenu = (unseenNotifications = 0): NavMenuItem[] => [
  {
    text: "Store",
    link: "/store",
    flag: FeatureFlag.STORE,
    icon: <NavStore />
  },
  {
    text: "Products",
    link: "/products",
    flag: FeatureFlag.PRODUCTS,
    icon: <NavProduct />
  },
  {
    text: "Sales",
    link: "/sales",
    flag: FeatureFlag.ORDERS,
    icon: <NavSale />,
    subMenu: [
      {
        text: "Orders",
        link: "/sales/orders",
        icon: <NavOrder />
      },
      {
        text: "Order templates",
        link: "/sales/templates",
        icon: <NavOrderTemplate />
      }
    ]
  },
  {
    text: "Expenses",
    link: "/expenses",
    flag: FeatureFlag.EXPENSES,
    icon: <NavExpense />
  },
  {
    text: "Templates",
    link: "/templates",
    // flag: FeatureFlag.TEMPLATES,
    icon: <NavTemplate />,
    subMenu: [
      {
        text: "Library",
        link: "/templates/library",
        icon: <NavLibrary />
      },
      {
        text: "Published",
        link: "/templates/published",
        icon: <NavPurchased />
      },
      {
        text: "Purchased",
        link: "/templates/purchased",
        icon: <NavLibrary />
      }
    ]
  },
  {
    text: (
      <span className="inline-flex justify-between items-center w-full">
        <span>Notifications</span>
        {unseenNotifications !== 0 && (
          <small className="text-white bg-secondary rounded-full w-6 h-6 flex items-center justify-center text-xs">
            {unseenNotifications}
          </small>
        )}
      </span>
    ),
    link: "/notifications",
    icon: <NavNotification />
  },
  {
    text: "Settings",
    link: "/settings",
    flag: FeatureFlag.SETTINGS,
    icon: <NavSetting />
  }
];

export const hasFeature = (featureFlag?: string, features?: ImmutableArray<Feature>): boolean => {
  return !!features?.find((feature) => !featureFlag || feature.code == featureFlag);
};
