import Amount from "components/Shared/Amount";
import ConfirmationDialog from "components/Shared/ConfirmationDialog";
import DateInput from "components/Shared/DateInput";
import IconButton from "components/Shared/IconButton";
import TextInput from "components/Shared/TextInput";
import { formatReadableDate } from "helpers/date";
import ArrowLeft from "icons/ArrowLeft";
import Delete from "icons/Delete";
import Edit from "icons/Edit";
import Plus from "icons/Plus";
import Save from "icons/Save";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { OrderDetailProps } from "types";
import { OrderItems } from "./OrderItems/OrderItems";
import { DropdownButton } from "components/Shared/DropdownButton/DropdownButton";
import Caret from "icons/Caret";
import Button from "components/Shared/Button";
import { InfoTooltip } from "components/Shared/InfoTooltip/InfoTooltip";

const OrderDetail = (props: OrderDetailProps): React.ReactElement => {
  const {
    orderDetail: { ref, ...orderDetail },
    handleConfirmDelete,
    deleteConfirmationMessage,
    handleDeleteConfirmation,
    handleDeleteCancellation,
    handleDeletePayment,
    creatingPayment,
    setCreatingPayment,
    handleFormChange,
    paymentForm,
    handleFormSubmit
  } = props;
  const navigate = useNavigate();

  const profit = orderDetail.amountPaid - orderDetail.productionCost.total;
  return (
    <div className="space-y-4 pb-4">
      <div className="px-4 sm:px-0">
        <Link to="../" className="font-bold text-sm	inline-flex" data-testid="backLink">
          <span className="text-primary mr-1">
            <ArrowLeft />
          </span>
          Back to orders
        </Link>
        <div className="flex flex-col sm:flex-row justify-between sm:items-center space-y-4 sm:space-y-0">
          <div className="w-full">
            <h2
              className="text-2xl md:text-4xl font-bold text-dark-grey pt-2 pb-6"
              data-testid="orderTitle">
              Order #{ref} details
            </h2>
            <p data-testid="customerName">
              <b>Customer:</b> {orderDetail.customer.name}
            </p>
            <p data-testid="orderDate">
              <b>Order date:</b> {formatReadableDate(orderDetail.date)}
            </p>
          </div>
          <div className="w-full">
            <div className="flex justify-between md:justify-end space-x-0 md:space-x-2">
              <DropdownButton
                items={[
                  { text: "Edit order", link: "edit" },
                  {
                    text: <span className="text-danger hover:text-white">Delete order</span>,
                    onClick: (): void =>
                      handleConfirmDelete({
                        ...orderDetail,
                        customerName: orderDetail.customer.name,
                        ref
                      })
                  }
                ]}>
                {(props) => (
                  <IconButton outlined>
                    Order options
                    <Caret {...props} />
                  </IconButton>
                )}
              </DropdownButton>
              <Link to="invoice" className="inline-flex">
                <Button size="md">Generate invoice</Button>
              </Link>
            </div>
            {orderDetail?.invoice?.createdAt && (
              <p className="text-right mt-2 text-sm">
                Last sent: {formatReadableDate(orderDetail?.invoice?.createdAt)}
              </p>
            )}
          </div>
        </div>
        <div className="justify-between bg-white rounded-lg mt-4 p-4 sm:p-6 space-y-4">
          <p className="font-bold text-xl" data-testid="">
            Payments received
            <InfoTooltip>
              The payments that have come in for this order. You can record part payments
              separately. Note that payments date cannot be before order date.
            </InfoTooltip>
          </p>
          <div className="space-y-3" data-testid="payments">
            {orderDetail.payments.map((payment) => (
              <p className="flex space-x-2" key={payment.id} data-testid="payment">
                <span data-testid="paymentDate" className="w-40">
                  {formatReadableDate(payment.date)}
                </span>
                <span className="font-bold w-24">
                  <Amount amount={payment.amount} />
                </span>
                <span
                  className="font-bold link text-danger"
                  data-testid="removePayment"
                  onClick={(): void => handleDeletePayment(payment)}>
                  <Delete />
                </span>
              </p>
            ))}
            {creatingPayment && (
              <form
                className="flex flex-wrap sm:space-x-2 items-center"
                data-testid="newPayment"
                onSubmit={handleFormSubmit}>
                <div className="w-full sm:w-40">
                  <DateInput
                    name="paymentDate"
                    onChange={handleFormChange}
                    value={paymentForm.paymentDate}
                    min={orderDetail.date}
                    label="Payment date"
                    data-testid="paymentDateInput"
                    inputSize="sm"
                  />
                </div>
                <div className="w-full sm:w-32">
                  <TextInput
                    type="number"
                    name="paymentAmount"
                    onChange={handleFormChange}
                    value={paymentForm.paymentAmount}
                    label="Amount paid"
                    data-testid="paymentAmount"
                    inputSize="sm"
                  />
                </div>
                <button
                  className="font-bold link mb-2 px-2"
                  data-testid="savePayment"
                  type="submit">
                  <Save />
                </button>
                <span
                  className="font-bold link mb-2 px-2 text-danger"
                  data-testid="cancelPayment"
                  onClick={(): void => setCreatingPayment(false)}>
                  <Delete />
                </span>
              </form>
            )}
          </div>
          {orderDetail.payments.length === 0 && (
            <p className="flex" data-testid="noPayments">
              No payments created for this order
            </p>
          )}
          <div>
            <IconButton
              icon={<Plus />}
              data-testid="createPayment"
              disabled={creatingPayment}
              onClick={(): void => setCreatingPayment(true)}>
              Record a payment
            </IconButton>
          </div>
        </div>
      </div>
      <div className="justify-between bg-white sm:rounded-lg p-4 sm:p-6 ">
        <div className="flex justify-between">
          <p className="font-bold text-xl" data-testid="">
            Order details
          </p>
          <Link to="edit?step=Products" className="link flex space-x-1 items-center">
            <Edit />
            <span>Update</span>
          </Link>
        </div>
        <OrderItems {...orderDetail} />
      </div>
      <div
        className={`rounded-lg border ${
          profit < 0 ? "bg-[#FEE5E6] border-danger" : "bg-[#E6F6F5] border-primary"
        }   p-4 pb-3 sm:p-6 sm:pb-3 mx-4 sm:mx-0`}>
        <div className=" flex justify-between">
          <div className="mb-4">
            <p>
              <b className="mr-1">Production cost:</b>
              <b data-testid="productionCost">
                <Amount amount={orderDetail.productionCost.total} />
              </b>
            </p>
            <p className="text-grey-50 text-xs mt-1" data-testid="orderNote">
              Components ( <Amount amount={orderDetail.productionCost.materials} />) + Add-ons ({" "}
              <Amount amount={orderDetail.productionCost.services} />) + Activities ({" "}
              <Amount amount={orderDetail.productionCost.activities} />)
            </p>
          </div>

          <Link to={"production"} className="link flex space-x-1 items-center">
            <Edit />
            <span>Update</span>
          </Link>
        </div>
        <hr className={`flex-1 h-0.5 m-0 ${profit < 0 ? "border-danger" : "border-primary"}`} />
        <p className="mt-3">
          <b className="mr-1">{profit >= 0 ? "Profit" : "Loss"}:</b>
          <b data-testid="productionCost">
            <Amount amount={profit} />
          </b>
        </p>
      </div>
      <div className="justify-between mx-4 sm:mx-0 bg-white rounded-lg p-4 sm:p-6 ">
        <div className="flex justify-between">
          <p className="font-bold text-xl mb-2" data-testid="">
            Order note
          </p>
          <Link to="edit?step=Additional information" className="link flex space-x-1 items-center">
            <Edit />
            <span>Update</span>
          </Link>
        </div>
        <p className="pt-2" data-testid="orderNote">
          {orderDetail.note}
        </p>
      </div>

      <ConfirmationDialog
        message={deleteConfirmationMessage}
        handleContinue={handleDeleteConfirmation}
        handleCancel={handleDeleteCancellation}
      />
    </div>
  );
};

export default OrderDetail;
