import ky from "ky";
import { appState } from "./store";

const api = ky.create({
  prefixUrl: process.env.REACT_APP_GATEWAY_URL,
  throwHttpErrors: true,
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set("Authorization", `Bearer ${appState.accessToken.get()}`);
        request.headers.set("businessId", appState.activeBusinessId.get() || "");
        if (process.env.NODE_ENV === "development") {
          request.headers.set("testuid", `${appState.user.get()?.uid}`);
        }
      }
    ],
    afterResponse: [
      async (request, options, response) => {
        const body = await response.json();
        if (response.status === 401 || response.status === 403) {
          window.location.href = "/signin";
        } else if (response.status >= 400 && response.status <= 500) {
          // console.log(body);
        } else if (!response.ok) {
          appState.request.set({ hasTimedOut: true });
        }
      }
    ]
  }
  // fetch: (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  //   const fn = fetch || globalThis.fetch.bind(globalThis);
  //   return fn(input, init).catch((err) => {
  //     // console.log(err instanceof TimeoutError, err instanceof HTTPError, err.message, err.status);
  //     if (err instanceof TimeoutError) {
  //       // Log a timeout to cause the app to reload
  //       appState.request.set({ hasTimedOut: true });
  //     }
  //     throw err;
  //   });
  // }
});

export default api;
