import { ProductActivityProps } from "types";
import React, { useState } from "react";
import Autocomplete from "components/Shared/Autocomplete";
import Cancel from "icons/Cancel";
import Accordion from "components/Shared/Accordion";
import TextInput from "components/Shared/TextInput";
import Info from "icons/Info";
import IconButton from "components/Shared/IconButton";
import ArrowLeft from "icons/ArrowLeft";
import ArrowRight from "icons/ArrowRight";
import Error from "components/Shared/Error";
import Amount from "components/Shared/Amount";
import { AutosaveButton } from "components/Shared/AutosaveButton/AutosaveButton";
import { Link } from "react-router-dom";
import InputGroup from "components/Shared/InputGroup";
import Select from "components/Shared/Select";
import Tag from "components/Shared/Tag/Tag";

const Activities = (props: ProductActivityProps): React.ReactElement => {
  const {
    handleAddActivity,
    handleRemoveActivity,
    productVariantActivities,
    handleVariantActivitiesChange,
    businessActivities,
    productActivities,
    loaders,
    handleFormSubmit,
    error,
    hasUnsavedChanges,
    variantsHaveChanges,
    productName
  } = props;
  const [activeKey, setActiveKey] = useState("");

  return (
    <div>
      <form className="pb-40" onSubmit={handleFormSubmit} data-testid="activitiesForm">
        <div className="bg-white md:rounded-lg px-4 md:px-6 py-4 mt-4 md:h-auto">
          <Error error={error} />
          <div>
            <Autocomplete
              type="text"
              name="activities"
              onDataSelected={(data) => {
                if (data) {
                  handleAddActivity(data.id, data.name);
                }
              }}
              value=""
              label="Add Activities"
              data-testid="activities"
              dataIdName="activities"
              data={
                businessActivities
                  .filter(
                    (businessActivity) =>
                      !productActivities.find(
                        (productActivity) =>
                          businessActivity.id === productActivity.businessActivityId
                      ) && businessActivity.isProductionActivity
                  )
                  .map((item) => ({ id: item.id, name: item.name })) || []
              }
              placeholder="Start typing to filter business add-ons"
              defaultData={
                <span className="text-grey-50 text-sm">
                  Can’t see what you are looking for?&nbsp;
                  <Link to="/expenses?tab=activities&new" className="link inline-flex">
                    <b>Create a new activity here</b>
                  </Link>
                </span>
              }
            />
            <div>
              <p className="font-bold">Activities in use</p>
              <div className="flex mt-4 flex-wrap" data-testid="productActivities">
                {productActivities.map((productActivity) => (
                  <Tag
                    key={productActivity.id}
                    name={productActivity.name}
                    onRemove={() => handleRemoveActivity(productActivity.id)}
                  />
                ))}
              </div>
            </div>
          </div>
          <p className="text-grey-50 px-4 mb-4 md:px-0 mt-2 sm:mt-6" data-testid="">
            {productActivities.length > 0
              ? `Set the activity duration for ${productName} variants.`
              : "No activities in use."}
          </p>
          {productVariantActivities.map(
            (productVariantActivity, productVariantActivityIndex): React.ReactElement => (
              <Accordion
                key={productVariantActivity.variantId}
                itemId={productVariantActivity.variantId}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                header={
                  <div className="inline-flex items-center justify-between flex-1 pr-6">
                    <span>
                      <b data-testid="variantName">{productVariantActivity.variantName}</b>
                      {productVariantActivity.activityTimes.filter(
                        (activityTime) => activityTime.time == 0
                      ).length > 0 && (
                        <span
                          className="ml-4 text-xs font-medium bg-warning text-dark-warning rounded px-2 py-1 inline-flex items-center"
                          data-testid="emptyActivityTimes">
                          <Info /> &nbsp;
                          {
                            productVariantActivity.activityTimes.filter(
                              (activityTime) => activityTime.time == 0
                            ).length
                          }
                        </span>
                      )}
                    </span>
                    {!variantsHaveChanges[productVariantActivity.variantId] && (
                      <Amount amount={productVariantActivity.totalCost} />
                    )}
                  </div>
                }>
                <div className="flex flex-col">
                  {productVariantActivity.activityTimes.map((activityTime) => (
                    <div
                      className="flex justify-between items-center pt-2 px-2 md:px-4  border-b border-grey-30"
                      key={activityTime.productionActivityId}
                      data-testid="activityTime">
                      <span data-testid="activityName" className="flex-1">
                        {
                          productActivities.find(
                            (productActivity) =>
                              productActivity.id == activityTime.productionActivityId
                          )?.name
                        }
                      </span>
                      <div className="flex-1 max-w-xs">
                        <InputGroup>
                          <div className="w-auto flex-1">
                            <TextInput
                              type="number"
                              name="time"
                              id="time"
                              value={activityTime.time}
                              required
                              onChange={(e): void =>
                                handleVariantActivitiesChange(
                                  e,
                                  productVariantActivity.variantId,
                                  activityTime.productionActivityId
                                )
                              }
                              label="Time"
                              data-testid="timeInput"
                              inputSize="sm"
                              min={0}
                              prepend={<span className="text-md">⏰</span>}
                            />
                          </div>
                          <div className="w-20">
                            <Select
                              id="unit"
                              inputSize="sm"
                              name="unit"
                              value={activityTime.unit}
                              required
                              onChange={(e): void =>
                                handleVariantActivitiesChange(
                                  e,
                                  productVariantActivity.variantId,
                                  activityTime.productionActivityId
                                )
                              }
                              data-testid="unit"
                              className="bg-soft-grey"
                              label="Unit">
                              <option value="" disabled>
                                Select a unit
                              </option>
                              <option value="HOUR">Hr</option> <option value="MINUTE">Min</option>
                            </Select>
                          </div>
                        </InputGroup>
                      </div>
                    </div>
                  ))}
                  <div className="flex justify-between items-center py-2 px-4 border-grey-30 bg-soft-grey text-primary">
                    <div>
                      {productVariantActivityIndex > 0 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              productVariantActivities[productVariantActivityIndex - 1].variantId
                            )
                          }
                          icon={<ArrowLeft />}
                          data-testid="prev"
                          outlined>
                          Previous
                        </IconButton>
                      )}
                    </div>
                    <div>
                      {productVariantActivityIndex < productVariantActivities.length - 1 && (
                        <IconButton
                          size="sm"
                          onClick={() =>
                            setActiveKey(
                              productVariantActivities[productVariantActivityIndex + 1].variantId
                            )
                          }
                          iconEnd={<ArrowRight />}
                          data-testid="next"
                          outlined>
                          Next variant
                        </IconButton>
                      )}
                    </div>
                  </div>
                </div>
              </Accordion>
            )
          )}
        </div>
        <div className="fixed left-0 right-0 bottom-0 bg-white sm:px-12 xl:px-40 py-6 text-center md:text-right z-10">
          <AutosaveButton
            disabled={!hasUnsavedChanges}
            hasChanges={hasUnsavedChanges}
            type="submit"
            loading={loaders.savingActivities}
            data-testid="submitButton"
            className="w-60"
          />
        </div>
      </form>
    </div>
  );
};

export default Activities;
