import React from "react";

export const NavTemplate = (): React.ReactElement => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.6923 16.979H2.30775C1.80258 16.979 1.375 16.804 1.025 16.454C0.675 16.104 0.5 15.6764 0.5 15.1713V3.78675C0.5 3.28159 0.675 2.854 1.025 2.504C1.375 2.154 1.80258 1.979 2.30775 1.979H11.827V3.479H2.30775C2.23075 3.479 2.16025 3.51109 2.09625 3.57525C2.03208 3.63925 2 3.70975 2 3.78675V15.1713C2 15.2483 2.03208 15.3188 2.09625 15.3828C2.16025 15.4469 2.23075 15.479 2.30775 15.479H17.6923C17.7693 15.479 17.8398 15.4469 17.9038 15.3828C17.9679 15.3188 18 15.2483 18 15.1713V8.556H19.5V15.1713C19.5 15.6764 19.325 16.104 18.975 16.454C18.625 16.804 18.1974 16.979 17.6923 16.979ZM4.38475 13.152H10.75V10.6713H4.38475V13.152ZM4.38475 8.28675H10.75V5.806H4.38475V8.28675ZM13.1348 13.152H15.6152V8.556H13.1348V13.152ZM16 6.479V4.479H14V2.979H16V0.979004H17.5V2.979H19.5V4.479H17.5V6.479H16Z"
      fill="currentColor"
    />
  </svg>
);
