import { CreateEditProductVariant } from "components/CreateEditProductVariant/CreateEditProductVariant";
import { useProductVariant } from "hooks/useProductVariant/useProductVariant";
import React from "react";
import LoadingComponent from "components/Shared/Loading";
import { useParams } from "react-router-dom";
import { SegmentEvent } from "types/enum";

const EditProductVariantPage = (): React.ReactElement => {
  const { productId } = useParams();
  const variantProps = useProductVariant({
    type: "Product",
    baseUri: `product/${productId}`,
    backURL: `/products/${productId}`,
    addEvent: SegmentEvent.VARIANT_ADDED,
    updateEvent: SegmentEvent.VARIANT_MODIFIED
  });
  const { loaders } = variantProps;

  return (
    <LoadingComponent loading={loaders.fetchingVariant}>
      <CreateEditProductVariant {...variantProps} />
    </LoadingComponent>
  );
};

export default EditProductVariantPage;
