import { CreateEditProductVariant } from "components/CreateEditProductVariant/CreateEditProductVariant";
import { useProductVariant } from "hooks/useProductVariant/useProductVariant";
import React from "react";
import { useParams } from "react-router-dom";
import { SegmentEvent } from "types/enum";

const CreateProductVariantPage = (): React.ReactElement => {
  const { productId } = useParams();
  const variantProps = useProductVariant({
    type: "Product",
    baseUri: `product/${productId}`,
    backURL: `/products/${productId}`,
    addEvent: SegmentEvent.VARIANT_ADDED,
    updateEvent: SegmentEvent.VARIANT_MODIFIED
  });

  return <CreateEditProductVariant {...variantProps} />;
};

export default CreateProductVariantPage;
