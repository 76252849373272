import api from "config/api";
import { useState } from "react";
import { Response, TemplatesLoaders, TemplateSummary } from "types";

export const useTemplates = () => {
  // UseStates
  const [templateSummary, setTemplateSummary] = useState<TemplateSummary>();
  const [loaders, setLoaders] = useState<TemplatesLoaders>({
    fetchingTemplates: false
  });

  const handleGetPublishedTemplates = async (): Promise<void> => {
    setLoaders((prev) => ({ ...prev, fetchingTemplates: true }));
    try {
      const json: Response<TemplateSummary> = await api.get("product-template/by/author").json();
      if (json.code === 200) {
        setTemplateSummary(json.data);
      }
    } catch (err) {
      console.error(err);
    }
    setLoaders((prev) => ({ ...prev, fetchingTemplates: false }));
  };

  return {
    handleGetPublishedTemplates,
    loaders,
    templateSummary
  };
};

export type UseTemplateTypes = ReturnType<typeof useTemplates>;
