import { ERROR_OCCURRED } from "constants/response";
import { capitalizeFirstLetter } from "./string";

export const extractErrorMessage = async (
  error: any,
  defaultError = ERROR_OCCURRED
): Promise<string> => {
  const json = await error.response.json();
  return capitalizeFirstLetter(
    (typeof json?.message == "string" ? json?.message : json?.message?.join(",")) || defaultError
  );
};
