import { CreateEditTemplate } from "components/CreateEditTemplate/CreateEditTemplate";
import LoadingComponent from "components/Shared/Loading";
import { useTemplate } from "hooks/useTemplate/useTemplate";
import React from "react";

export const ConfigureTemplatePage = (): React.ReactElement => {
  const props = useTemplate();

  return (
    <LoadingComponent loading={props.loaders.fetchingProduct}>
      <CreateEditTemplate {...props} />
    </LoadingComponent>
  );
};
